'use client';  // This is a client component

import { signIn, useSession } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";

export default function Home() {
  const { data: session, status } = useSession();

  if (status === 'loading') {
    return <p>Loading...</p>;
  }

  if (session) {
    return (
      <div className="h-screen w-full flex justify-center items-center flex-col ">
        <p>You are currently logged in as {session?.user?.email}</p>
        {session?.user?.image && (
          <Image
            src={session.user.image}
            alt="User Profile Picture"
            width={50}
            height={50}
            className="rounded-full"
          />
        )}
  
        <button onClick={() => signIn('slack')}>Connect with Slack</button>
      </div>
    );
  }

  return (
    <div className="h-screen w-full flex justify-center items-center">
      <Link href={'/api/auth/signin'} ><p className=" text-indigo-700 underline" >Ping the Bleep</p></Link>

    </div>
  );
}
